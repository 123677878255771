var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"board-calendar"},[_c('router-view'),_c('div',{staticClass:"functions"},[_c('div',{staticClass:"filters-left"},[_c('we-button',{staticClass:"disabled",attrs:{"text":_vm.$t('today')},on:{"click":_vm.setToday}}),_c('we-button',{class:_vm.filterActive ? 'primary' : 'disabled',attrs:{"text":_vm.$t('filter'),"icon":"sliders-h"},on:{"click":function($event){return _vm.$router.push({ name: 'filterTaskInCalendar' })}}})],1),_c('div',{staticClass:"change-date"},[_c('we-icon-button',{attrs:{"iconSize":"small","icon":"chevron-left","name":_vm.$t('prev')},on:{"click":_vm.prev}}),(_vm.$refs.calendar)?_c('div',{staticClass:"date-title"},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('we-icon-button',{attrs:{"iconSize":"small","icon":"chevron-right","name":_vm.$t('next')},on:{"click":_vm.next}})],1),_c('div',{staticClass:"filters-right"},[_c('we-icon-button',{attrs:{"icon":"sync-alt","name":_vm.$t('reload')},on:{"click":_vm.doRequests}}),_c('we-input',{attrs:{"type":"global-select","clearable":false,"items":_vm.typeList},on:{"input":_vm.redLine,"change":_vm.saveLocalStorage},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)]),_c('div',{staticClass:"we-calendar"},[_c('v-calendar',{ref:"calendar",attrs:{"locale":_vm.$i18n.locale,"color":"primary","events":_vm.calendarTasks,"event-color":_vm.getEventColor,"type":_vm.type,"event-more-text":"Mais {0} tarefas"},on:{"click:event":_vm.openDeal,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":function($event){return _vm.updateCalendar({ evt: $event })}},scopedSlots:_vm._u([{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
return [_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.calendar.timeToY(_vm.calendar.times.now) + 'px' })})]}},{key:"event",fn:function(ref){
var event = ref.event;
return [_c('span',{staticClass:"calendar-task",class:{
            source:
              event.source.length > 0 &&
              event.source != 'other' &&
              event.source != 'wepipe'
          }},[_c('strong',[_vm._v(_vm._s(event.startHour))]),(
              event.source &&
                event.source.length > 0 &&
                ['google', 'outlook'].includes(event.source)
            )?_c('img',{attrs:{"src":_vm.imageSource(event.source)}}):_vm._e(),_vm._v(" "+_vm._s(event.name)+" ")])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1),_c('we-loading-overflow',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }