<i18n>
{
  "en": {
    "create": "New task",
    "filter": "Filter",
    "export-results": "Export results",
    "edit-filtered": "Edit filtered data",
    "delete-filtered": "Delete filtered data",
    "as-csv": "as CSV",
    "export": "Export",
    "export-all": "Export all",
    "reload": "Reload",
    "not-found": "Tasks not found",
    "not-found-description": "Check your filter again or click on button below to create a new one",
    "column-1": "Name",
    "column-2": "Status",
    "column-3": "Owner",
    "column-4": "Related card",
    "column-5": "Time to finish",
    "time-over": "Time is over",
    "days": "days",
    "hours": "hours",
    "and": "and",
    "minutes": "minutes",
    "finish-task": "Finish task",
    "task-finished": "Task finished"
  },
  "pt-BR": {
    "create": "Nova tarefa",
    "filter": "Filtro",
    "export-results": "Exportar resultados",
    "edit-filtered": "Editar registros filtrados",
    "delete-filtered": "Deletar registros filtrados",
    "as-csv": "como CSV",
    "export": "Exportar",
    "export-all": "Exportar tudo",
    "reload": "Recarregar",
    "not-found": "Nenhuma tarefa encontrada",
    "not-found-description": "Verifique seu filtro novamente ou clique no botão abaixo para criar",
    "column-1": "Nome",
    "column-2": "Status",
    "column-3": "Responsável",
    "column-4": "Card",
    "column-5": "Tempo para conclusão",
    "time-over": "Tempo esgotado",
    "days": "dias",
    "hours": "horas",
    "and": "e",
    "minutes": "minutos",
    "finish-task": "Finalizar tarefa",
    "task-finished": "Tarefa finalizada"
  }
}
</i18n>

<template>
  <board-list class="tasks">
    <router-view />
    <template #filters>
      <we-pagination :pages="tasksPages" @change="selectPage($event)" />
      <we-button
        class="mobile-icon"
        :class="filterActive ? 'primary' : 'disabled'"
        :text="$t('filter')"
        icon="sliders-h"
        @click="$router.push({ name: 'filterTaskInTasksList' })"
        cy="filter-in-tasks"
      />
      <we-balloon v-if="filterActive">
        <template #action>
          <we-icon-button
            icon="download"
            :name="$t('export-results')"
            color="icon-primary"
          />
        </template>
        <template #balloon>
          <div class="balloon-item" @click="exportFilteredFile('csv')">
            <font-awesome-icon icon="file-csv" />
            <div class="text">
              {{ $t("export-results") }} <b>{{ $t("as-csv") }}</b>
            </div>
          </div>
        </template>
      </we-balloon>
      <we-icon-button
        v-if="filterActive"
        :icon="['fas', 'edit']"
        :name="$t('edit-filtered')"
        @click="massUpdateOpened = true"
        cy="update-filtered-tasks"
      />
      <mass-update
        :open="massUpdateOpened"
        @close="massUpdateOpened = false"
        @confirm="doRequests({ reset: false })"
        :total-records="tasksPages.total"
      />
      <we-icon-button
        v-if="filterActive"
        :icon="['far', 'trash-alt']"
        :name="$t('delete-filtered')"
        color="icon-red"
        @click="confirmDeleteOpened = true"
        cy="delete-filtered-tasks"
      />
      <confirm-modal
        :open="confirmDeleteOpened"
        :total-records="tasksPages.total"
        @confirm="deleteFilteredData()"
        @close="confirmDeleteOpened = false"
      />
    </template>
    <template #actions>
      <we-icon-button
        icon="sync-alt"
        @click="doRequests({ reset: false })"
        :name="$t('reload')"
        cy="reload-tasks"
      />
      <we-balloon>
        <template #action>
          <we-icon-button
            icon="download"
            :name="$t('export')"
            cy="export-tasks-trigger"
          />
        </template>
        <template #balloon>
          <div class="balloon-item" @click="exportFile('csv')">
            <font-awesome-icon icon="file-csv" />
            <div class="text" cy="export-tasks">
              {{ $t("export-all") }} <b>{{ $t("as-csv") }}</b>
            </div>
          </div>
        </template>
      </we-balloon>
    </template>
    <template #list>
      <we-loading-overflow :loading="loadingTasks" />
      <div class="labels">
        <div
          class="label"
          :class="elem.orderBy === tasksSort.orderBy ? tasksSort.direction : ''"
          v-for="elem in sortElements"
          :key="elem.orderBy"
          @click="orderBy(elem)"
        >
          <we-text-tooltip class="name" :text="elem.name" lines="1" />
          <div v-if="elem.orderBy" class="icon">
            <font-awesome-icon icon="sort-up" />
            <font-awesome-icon icon="sort-down" />
          </div>
        </div>
      </div>
      <div class="items">
        <we-not-found v-if="tasks.length === 0">
          <template #title> {{ $t("not-found") }} </template>
          <template #description>
            {{ $t("not-found-description") }}
          </template>
          <template #actions>
            <we-button
              class="success"
              :text="$t('create')"
              icon="plus"
              @click="$router.push({ name: 'createTaskInTasksList' })"
            />
          </template>
        </we-not-found>
        <div
          class="card-list"
          v-for="(dealTask, i) in tasks"
          :key="i"
          @click="openTask(dealTask.id)"
          :class="{ 'task-completed': dealTask.is_finished }"
        >
          <div class="column">
            <v-tooltip top :disabled="!isDesktop">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-checkbox
                    v-model="dealTask.is_finished"
                    color="success"
                    @click.stop="changeTaskCompletion(dealTask)"
                    hide-details
                  />
                </div>
              </template>
              <span v-if="dealTask.is_finished">{{ $t("task-finished") }}</span>
              <span v-else>{{ $t("finish-task") }}</span>
            </v-tooltip>
          </div>
          <div class="column">
            <img
              v-if="
                dealTask.source &&
                  dealTask.source.length > 0 &&
                  ['google', 'outlook'].includes(dealTask.source)
              "
              :src="imageSource(dealTask.source)"
              class="source-img"
            />
            <div
              class="task-color"
              v-if="dealTask && !dealTask.name && dealTask.task_type"
              :class="dealTask.task_type.color"
            ></div>
            <we-text-tooltip
              class="name"
              :text="getTaskName(dealTask)"
              lines="1"
            />
          </div>
          <div class="column only-desktop">
            <div class="data">
              <we-text-tooltip class="name" :text="dealTask.status" lines="1" />
            </div>
          </div>
          <div class="column only-desktop">
            <we-text-tooltip
              v-if="dealTask.responsible_user"
              class="name"
              :text="
                `${dealTask.responsible_user.first_name} ${dealTask.responsible_user.last_name}`
              "
              lines="1"
            />
            <span v-else>-</span>
          </div>
          <div class="column only-desktop">
            <we-text-tooltip
              v-if="dealTask.deal"
              class="name"
              :text="getDealName(dealTask.deal)"
              lines="1"
            />
            <span v-else>-</span>
          </div>
          <div class="column only-desktop">
            <we-text-tooltip
              class="name"
              :text="`${getTimeToComplete(dealTask.end)}`"
              lines="1"
            />
          </div>
        </div>
      </div>
    </template>
  </board-list>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      massUpdateOpened: false,
      confirmDeleteOpened: false,
      createValid: false,
      loadingBtn: false,
      loadingTasks: true,
      currentPage: 1,
      sortElements: [
        {
          name: this.$t("column-1"),
          orderBy: "name",
          direction: "desc"
        },
        {
          name: this.$t("column-2"),
          orderBy: "status",
          direction: "desc"
        },
        {
          name: this.$t("column-3")
        },
        {
          name: this.$t("column-4")
        },
        {
          name: this.$t("column-5")
        }
      ],
      taskCompleted: false
    };
  },
  computed: {
    tasks() {
      return this.$store.getters.getFilteredTasks;
    },
    tasksPages() {
      return this.$store.getters.getTasksPages;
    },
    tasksSort() {
      return this.$store.getters.getTasksSort;
    },
    loggedUserId() {
      return this.$store.getters.getLoggedUserId;
    },
    customerId() {
      return this.$store.getters.getLoggedUser?.customer_id;
    },
    currentResponsibleUser() {
      let userResponsible = {};
      userResponsible = this.users.find(e => {
        return e.id === this.currentDealTask.responsible_user_id;
      });
      return userResponsible;
    },
    filterActive() {
      return this.$store.getters.getFilterActive;
    },
    exportParams() {
      return this.$store.getters.getExportParams;
    }
  },
  methods: {
    ...mapActions([
      "filterTasksRequest",
      "updateDealTaskRequest",
      "exportRequest",
      "exportFilteredAsCsvRequest",
      "loggedUserRequest",
      "deleteFilteredRecords"
    ]),
    async deleteFilteredData() {
      this.loadingTasks = true;
      await this.deleteFilteredRecords();
      await this.doRequests({
        reset: true,
        page: this.tasksPages.current_page
      });
    },
    getTaskName(task) {
      return task?.name ? task?.name : task?.task_type?.name;
    },
    exportFilteredFile(ext) {
      if (ext === "csv") {
        this.exportFilteredAsCsvRequest("tasks-filtered");
      }
    },
    exportFile() {
      const payload = {
        entity: "tasks"
      };

      this.exportRequest(payload);
    },
    imageSource(source) {
      return require(`@/assets/task-source/${source}.svg`);
    },
    openTask(id) {
      this.$router.push({
        name: "editTaskInTasksList",
        params: { taskId: id }
      });
    },
    async orderBy(elem) {
      this.loadingTasks = true;

      if (elem.direction === "desc") {
        elem.direction = "asc";
      } else {
        elem.direction = "desc";
      }

      this.$store.commit("setTasksSort", elem);

      await this.filterTasksRequest({
        filters: this.exportParams?.payload?.filters,
        page: this.tasksPages.current_page,
        orderBy: elem.orderBy,
        direction: elem.direction
      });

      this.loadingTasks = false;
    },
    async doRequests({ reset, page = this.tasksPages.current_page }) {
      this.loadingTasks = true;

      if (reset) {
        this.tasksPages.current_page = this.initialPage;
        this.$store.commit("setFilterActive", false);
        this.$store.commit("setTasksSort", {
          orderBy: "",
          direction: "",
          name: ""
        });
      }

      let orderBy = {};
      if (this.tasksSort.orderBy) {
        orderBy = {
          orderBy: this.tasksSort.orderBy,
          direction: this.tasksSort.direction
        };
      }

      if (this.filterActive) {
        await this.filterTasksRequest({
          filters: this.exportParams?.payload?.filters,
          page: page,
          ...orderBy
        });
      } else {
        await this.filterTasksRequest({
          filters: [
            {
              field: "customer_id",
              operator: "=",
              value: this.customerId
            }
          ],
          page: page,
          ...orderBy
        });
      }

      this.loadingTasks = false;
    },
    async selectPage(val) {
      this.doRequests({ page: val });
    },
    getDealName(deal) {
      return deal ? deal.name : "";
    },
    changeTaskCompletion(task) {
      if (task.is_finished) {
        task.is_finished = true;
      } else {
        task.is_finished = false;
      }
      const payload = {
        id: task.id,
        status: task.status,
        start: task.start,
        end: task.end,
        note: task.note,
        deal_id: task?.deal_id,
        creator_user_id: task?.creator_user_id,
        responsible_user_id: task?.responsible_user_id,
        task_type_id: task.task_type.id,
        is_finished: task.is_finished
      };
      this.updateDealTaskRequest(payload);
    },
    getMsBetweenDates(endTask) {
      let today = new Date();
      let end = new Date(endTask);
      let milliseconds = Math.abs(end - today);

      return today > end ? 0 : milliseconds;
    },
    getTimeToComplete(endTask) {
      const milliseconds = this.getMsBetweenDates(endTask);

      const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
      const daysms = milliseconds % (24 * 60 * 60 * 1000);
      const hours = Math.floor(daysms / (60 * 60 * 1000));
      const hoursms = milliseconds % (60 * 60 * 1000);
      const minutes = Math.floor(hoursms / (60 * 1000));

      if (milliseconds <= 0) {
        return this.$t("time-over");
      }

      return `${days} ${this.$t("days")}, ${hours} 
      ${this.$t("hours")} ${this.$t("and")} 
      ${minutes} ${this.$t("minutes")}`;
    }
  },
  async mounted() {
    if (!this.customerId) {
      await this.loggedUserRequest();
    }

    await this.doRequests({ reset: true, page: 1 });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.reload) {
      this.doRequests({ reset: true, page: this.tasksPages.current_page });
    }
    next();
  }
};
</script>

<style lang="scss">
.tasks-board {
  background: var(--background-2);
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
