<i18n>
{
  "en": {
    "today": "Today",
    "filter": "Filter",
    "prev": "Previous",
    "next": "Next",
    "reload": "Reload",
    "day": "Day",
    "week": "Week",
    "month": "Month"
  },
  "pt-BR": {
    "today": "Hoje",
    "filter": "Filtrar",
    "prev": "Anterior",
    "next": "Próximo",
    "reload": "Recarregar",
    "day": "Dia",
    "week": "Semana",
    "month": "Mês"
  }
}
</i18n>

<template>
  <div class="board-calendar">
    <router-view />
    <div class="functions">
      <div class="filters-left">
        <we-button class="disabled" :text="$t('today')" @click="setToday" />
        <we-button
          :class="filterActive ? 'primary' : 'disabled'"
          :text="$t('filter')"
          icon="sliders-h"
          @click="$router.push({ name: 'filterTaskInCalendar' })"
        />
      </div>
      <div class="change-date">
        <we-icon-button
          @click="prev"
          iconSize="small"
          icon="chevron-left"
          :name="$t('prev')"
        />
        <div class="date-title" v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </div>
        <we-icon-button
          @click="next"
          iconSize="small"
          icon="chevron-right"
          :name="$t('next')"
        />
      </div>
      <div class="filters-right">
        <we-icon-button
          icon="sync-alt"
          @click="doRequests"
          :name="$t('reload')"
        />
        <we-input
          type="global-select"
          :clearable="false"
          v-model="type"
          @input="redLine"
          @change="saveLocalStorage"
          :items="typeList"
        />
      </div>
    </div>
    <div class="we-calendar">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :locale="$i18n.locale"
        color="primary"
        :events="calendarTasks"
        :event-color="getEventColor"
        :type="type"
        event-more-text="Mais {0} tarefas"
        @click:event="openDeal"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="updateCalendar({ evt: $event })"
      >
        <template v-slot:day-body="{ date, week }">
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: calendar.timeToY(calendar.times.now) + 'px' }"
          ></div>
        </template>
        <template v-slot:event="{ event }">
          <span
            class="calendar-task"
            :class="{
              source:
                event.source.length > 0 &&
                event.source != 'other' &&
                event.source != 'wepipe'
            }"
          >
            <strong>{{ event.startHour }}</strong>
            <img
              v-if="
                event.source &&
                  event.source.length > 0 &&
                  ['google', 'outlook'].includes(event.source)
              "
              :src="imageSource(event.source)"
            />
            {{ event.name }}
          </span>
        </template>
      </v-calendar>
    </div>
    <we-loading-overflow :loading="loading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import "@/styles/_calendar.scss";
export default {
  data() {
    return {
      loading: true,
      focus: "",
      search: "",
      type: "month",
      typeList: [
        {
          text: this.$t("month"),
          value: "month"
        },
        {
          text: this.$t("week"),
          value: "week"
        },
        {
          text: this.$t("day"),
          value: "day"
        }
      ],
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day"
      },
      value: "",
      ready: false,
      selectedMonth: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "green",
        "error",
        "info",
        "success",
        "warning",
        "orange"
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party"
      ],
      taskTab: "CALENDAR"
    };
  },
  computed: {
    calendar() {
      return this.$refs.calendar;
    },
    calendarTasks() {
      return this.$store.getters.getCalendarTasks;
    },
    tasksFilterParams() {
      return this.$store.getters.getTasksFilterParams;
    },
    filterActive() {
      return this.$store.getters.getFilterActive;
    },
    exportParams() {
      return this.$store.getters.getExportParams;
    },
    calendarViewDate() {
      return this.$store.getters.getCalendarViewDate;
    }
  },
  methods: {
    ...mapActions(["calendarTasksRequest"]),
    saveLocalStorage() {
      localStorage.setItem("we-calendar-type", this.type);
    },
    imageSource(source) {
      return require(`@/assets/task-source/${source}.svg`);
    },
    updateCalendar({ evt }) {
      let firstDay = new Date(evt.start.year, evt.start.month - 1, 1);
      let lastDay = new Date(evt.end.year, evt.end.month, 0);

      firstDay = this.$options.filters.isoDate(firstDay).slice(0, 10);
      lastDay = this.$options.filters.isoDate(lastDay).slice(0, 10);

      if (Date.parse(evt.start.date) < Date.parse(firstDay)) {
        firstDay = evt.end.date;
      }

      if (Date.parse(evt.end.date) > Date.parse(lastDay)) {
        lastDay = evt.end.date;
      }

      let selectedMonthFilters = [
        {
          field: "start",
          operator: ">=",
          value: firstDay
        },
        {
          field: "end",
          operator: "<=",
          value: `${lastDay} 23:59:59`
        }
      ];

      this.$store.commit("setCalendarViewDate", selectedMonthFilters);

      if (
        this.selectedMonth != evt.start.month ||
        this.selectedMonth != evt.end.month
      ) {
        this.doRequests();
        this.selectedMonth = evt.start.month;
      }
    },
    async doRequests() {
      this.loading = true;

      if (this.filterActive) {
        await this.calendarTasksRequest({
          filters: [...this.exportParams?.payload?.filters]
        });
      } else {
        await this.calendarTasksRequest();
      }

      this.loading = false;
    },
    redLine(val) {
      if (val != "month") {
        this.updateTime();
        this.scrollToTime();
      }
    },
    getCurrentTime() {
      return this.calendar
        ? this.calendar.times.now.hour * 60 + this.calendar.times.now.minute
        : 0;
    },
    scrollToTime() {
      setTimeout(() => {
        const time = this.getCurrentTime();
        const first = Math.max(0, time - (time % 30) - 30);

        this.calendar.scrollToTime(first);
      }, 100);
    },
    updateTime() {
      setInterval(() => {
        this.calendar.updateTimes();
      }, 60 * 1000);
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    openDeal({ nativeEvent, event }) {
      this.$router.push({
        name: "editTaskInCalendar",
        params: { taskId: event.id }
      });
      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    changeTab(type) {
      this.taskTab = type;
      if (type === "LIST") {
        this.$router.push({
          name: "tasksList"
        });
      } else {
        this.$router.push({
          name: "tasks"
        });
      }
    }
  },
  async mounted() {
    this.type = localStorage.getItem("we-calendar-type") || "month";
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.reload) {
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
.board-calendar {
  background: var(--background-1);
  width: 100%;
  height: 100%;
  position: relative;

  .functions {
    padding: 13px 50px;
    display: flex;
    justify-content: space-between;

    .filters-left {
      display: flex;
      .we-button:first-child {
        margin-right: 18px;
      }
    }

    .filters-right {
      display: flex;
      .we-input:last-child {
        margin-left: 18px;
      }
    }

    .change-date {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .date-title {
        font-size: 18px;
        color: var(--text-1);
        margin: 0 14px;
      }
    }

    .we-input {
      max-width: 160px;
    }
  }

  .we-calendar {
    width: 100%;
    padding: 0 50px;
    height: 600px;
    height: 100%;
    max-height: calc(100vh - 138px);
  }
}
</style>
