<i18n>
{
  "en": {
    "title": "Tasks",
    "calendar": "Calendar",
    "list": "List",
    "create": "New task",
    "create-mobile": "New"
  },
  "pt-BR": {
    "title": "Tarefas",
    "calendar": "Calendário",
    "list": "Lista",
    "create": "Nova tarefa",
    "create-mobile": "Nova"
  }
}
</i18n>

<template>
  <div id="tasks">
    <nav-top :title="$t('title')">
      <template #top-content>
        <div class="select-rounded">
          <router-link
            :to="{ name: 'tasksCalendar' }"
            custom
            v-slot="{ navigate, isActive }"
            active-class="active"
          >
            <span
              @click="navigate"
              class="option"
              :class="{ active: isActive }"
              role="link"
            >
              <div class="icon">
                <font-awesome-icon icon="calendar-days" />
              </div>
              {{ $t("calendar") }}
            </span>
          </router-link>
          <router-link
            :to="{ name: 'tasksList' }"
            custom
            v-slot="{ navigate, isActive }"
          >
            <span
              @click="navigate"
              class="option"
              :class="{ active: isActive }"
              role="link"
            >
              <div class="icon" @click="navigate">
                <font-awesome-icon icon="list" />
              </div>
              {{ $t("list") }}
            </span>
          </router-link>
        </div>
        <we-button
          class="success mobile-icon header-action"
          :text="$t('create')"
          :mobile-text="$t('create-mobile')"
          icon="plus"
          @click="openCreateTask"
          cy="create-task-cta"
        />
      </template>
    </nav-top>
    <div class="board">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openCreateTask() {
      if (this.$route.name === "tasksCalendar") {
        this.$router.push({
          name: "createTaskInCalendar"
        });
      } else if (this.$route.name === "tasksList") {
        this.$router.push({
          name: "createTaskInTasksList"
        });
      }
    }
  }
};
</script>

<style lang="scss">
#tasks {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;

  @include mobile {
    display: block;
  }

  .board {
    background: var(--background-2);
    width: 100%;
    height: 100%;
  }
}
</style>
